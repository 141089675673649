import api from './api';

class EventsService {
  get(id) {
    return api.get(`/events/${id}`).then((res) => res.data);
  }

  find(params) {
    return api.get('/events', { params }).then((res) => res.data);
  }

  findByStudents(params) {
    return api.get('/events/total/student', { params }).then((res) => res.data);
  }

  findBySessions(params) {
    return api.get('/events/total/session', { params }).then((res) => res.data);
  }

  findBySessionsName(params) {
    return api.get('/events/total/session/name', { params }).then((res) => res.data);
  }
}

export default new EventsService();
